<template>
  <div class="login">
    <div class="login__top">
      <div class="login__wrap">
        <div class="login__col login__col--left pa-6">
          <Logo
            :logo-key="logoKey"
            :enable-link="(false)"
            class="mx-0"
          />
        </div>
        <div class="login__col login__col--right pr-6">
          <v-card class="pa-12">
            <h2 class="headline mb-4">
              Kaksivaiheinen tunnistautuminen
            </h2>
            <v-row v-if="OtpAuthUrl">
              Ota kaksivaiheinen tunnistautuminen käyttöön. Rekisteröi puhelimesi käyttämällä
              tunnistautumissovellusta.
              <vue-qr
                :text="OtpAuthUrl"
                :size="200"
              >
              </vue-qr>
              Tai ota käyttöön kaksivaiheinen tunnistautuminen sähköpostilla painamalla alla olevaa
              painiketta.
              <v-btn
                class="mt-4 mb-8"
                color="blue"
                @click="MFAEmail"
              >
                Sähköposti
              </v-btn>
            </v-row>
            <v-row v-else-if="emailLaite">
              <v-btn
                class="mb-6"
                color="blue"
                @click="lahetaEmailTunniste"
              >
                Lähetä tunniste sähköpostiin
              </v-btn>
            </v-row>
            <v-row>
              Syötä tunniste tunnistaumissovelluksesta tai sähköpostista
              <FormMFA
                v-model="MFALomake"
                class="flex-grow-1"
                :loading="loading"
                @submit="lahetaTunniste"
              >
              </FormMFA>
            </v-row>
          </v-card>
        </div>
      </div>
      <div class="login__wrap">
        <div class="login__col login__col--left pa-6"></div>
        <div class="login__col login__col--right pa-6 loginHelp pt-6">
          <p>
            <a
              @click="kirjauduUlos"
            >
              Kirjaudu uudelleen
            </a>
          </p>
          Palvelu on käytössä vain toimeksiantaja-asiakkaillemme.
        </div>
      </div>
    </div>
    <BusinessFooter />
  </div>
</template>

<script>

import {
  BusinessFooter,
  Logo,
  FormMFA,
} from '@/components'

import VueQr from 'vue-qr'

export default {
  name: 'Kirjaudu',
  components: {
    BusinessFooter,
    Logo,
    VueQr,
    FormMFA,
  },
  data () {
    return {
      loading: false,
      logoKey: '',
      formSalasananUusimispyynto: {},
      OtpAuthUrl: null,
      MFALomake: {},
      emailLaite: null,
    }
  },
  created () {
    this.logoKey = (process.env.VUE_APP_LOGO_LOGIN) ? process.env.VUE_APP_LOGO_LOGIN : process.env.VUE_APP_LOGO

    this.MFA()
  },
  methods: {
    async MFA () {
      this.loading = true

      try {
        const response = await this.$doRequestWithTimeout(
          this.$api.MFA,
          { method: 'GET' },
        )

        if (!response.success) {
          throw new this.$HttpError(response)
        }

        this.OtpAuthUrl = response.result.body.otpauth_url
        this.emailLaite = response.result.body.email_laite
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      } finally {
        this.loading = false
      }
    },

    async lahetaTunniste () {
      this.loading = true

      try {
        // haetaan kaksivaiheisen tunnistautumisen tokenit
        const response = await this.$doRequestWithTimeout(
          this.$api.MFAToken,
          {
            method: 'POST',
            body: {
              ...this.MFALomake,
            },
          },
        )

        if (response.success) {
          this.uudelleenohjaaKirjautunutKayttaja()
        }
      } catch (e) {
        this.$naytaVirheilmoitus(e.message)
      } finally {
        this.loading = false
        this.MFALomake = {}
      }
    },

    uudelleenohjaaKirjautunutKayttaja () {
      const nextPath = this.$router.currentRoute.query.next

      if (nextPath) {
        this.$router.replace({ path: nextPath })
      } else {
        const name = process.env.VUE_APP_LOGIN_REDIRECT_ROUTE || 'tyopoyta'
        this.$router.replace({ name })
      }
    },

    kirjauduUlos () {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')

      this.$store.dispatch('user/clearDetails')

      const redirectUrl = process.env.VUE_APP_LOGOUT_REDIRECT_URL
      if (redirectUrl) {
        window.location.replace(redirectUrl)

        return
      }

      this.$router.push({ name: 'kirjaudu' })
    },

    async MFAEmail () {
      this.loading = true

      try {
        const response = await this.$doRequestWithTimeout(
          this.$api.MFAEmail,
          { method: 'POST' },
        )

        if (response.success) {
          this.$naytaInfoilmoitus('Tunniste lähetetty sähköpostiin.')
        } else {
          throw new this.$api.HTTPError()
        }
      } catch (e) {
        this.$naytaVirheilmoitus(e.message)
      } finally {
        this.loading = false
      }
    },

    async lahetaEmailTunniste () {
      this.loading = true

      try {
        const response = await this.$doRequestWithTimeout(
          this.$api.MFALahetaEmailTunniste,
          { method: 'GET' },
        )

        if (response.success) {
          this.$naytaInfoilmoitus('Tunniste lähetetty sähköpostiin.')
        } else {
          throw new this.$api.HTTPError()
        }
      } catch (e) {
        this.$naytaVirheilmoitus(e.message)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/loginStyles.scss';
</style>
