var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login" },
    [
      _c("div", { staticClass: "login__top" }, [
        _c("div", { staticClass: "login__wrap" }, [
          _c(
            "div",
            { staticClass: "login__col login__col--left pa-6" },
            [
              _c("Logo", {
                staticClass: "mx-0",
                attrs: { "logo-key": _vm.logoKey, "enable-link": false },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "login__col login__col--right pr-6" },
            [
              _c(
                "v-card",
                { staticClass: "pa-12" },
                [
                  _c("h2", { staticClass: "headline mb-4" }, [
                    _vm._v(" Kaksivaiheinen tunnistautuminen "),
                  ]),
                  _vm.OtpAuthUrl
                    ? _c(
                        "v-row",
                        [
                          _vm._v(
                            " Ota kaksivaiheinen tunnistautuminen käyttöön. Rekisteröi puhelimesi käyttämällä tunnistautumissovellusta. "
                          ),
                          _c("vue-qr", {
                            attrs: { text: _vm.OtpAuthUrl, size: 200 },
                          }),
                          _vm._v(
                            " Tai ota käyttöön kaksivaiheinen tunnistautuminen sähköpostilla painamalla alla olevaa painiketta. "
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-4 mb-8",
                              attrs: { color: "blue" },
                              on: { click: _vm.MFAEmail },
                            },
                            [_vm._v(" Sähköposti ")]
                          ),
                        ],
                        1
                      )
                    : _vm.emailLaite
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mb-6",
                              attrs: { color: "blue" },
                              on: { click: _vm.lahetaEmailTunniste },
                            },
                            [_vm._v(" Lähetä tunniste sähköpostiin ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    [
                      _vm._v(
                        " Syötä tunniste tunnistaumissovelluksesta tai sähköpostista "
                      ),
                      _c("FormMFA", {
                        staticClass: "flex-grow-1",
                        attrs: { loading: _vm.loading },
                        on: { submit: _vm.lahetaTunniste },
                        model: {
                          value: _vm.MFALomake,
                          callback: function ($$v) {
                            _vm.MFALomake = $$v
                          },
                          expression: "MFALomake",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "login__wrap" }, [
          _c("div", { staticClass: "login__col login__col--left pa-6" }),
          _c(
            "div",
            { staticClass: "login__col login__col--right pa-6 loginHelp pt-6" },
            [
              _c("p", [
                _c("a", { on: { click: _vm.kirjauduUlos } }, [
                  _vm._v(" Kirjaudu uudelleen "),
                ]),
              ]),
              _vm._v(
                " Palvelu on käytössä vain toimeksiantaja-asiakkaillemme. "
              ),
            ]
          ),
        ]),
      ]),
      _c("BusinessFooter"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }